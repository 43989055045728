<template>
  <div class="container">
<!--    <div class="back">
      <img src="@/assets/common/icon-left.png" @click="goBack"/>
      {{this.$t('case.header.title')}}
    </div>-->
    <div class="warn">
      <div>{{this.$t('case.header.warnMsg')}}</div>
    </div>

    <div class="notify" v-show="showNotify">
      <div class="notify-title">
        <i class="el-icon-success" width="24" style="color:green"/>
        <label>{{this.$t('case.notify.title')}}</label>
        <i class="el-icon-close" @click="goBack" style="font-size: 24px"/>
      </div>
      <div class="notify-content">
        <p class="notify-description">{{this.$t('case.notify.content')}}</p>
      </div>
    </div>

    <div class="container-form">
      <el-form class="form" ref="form" :model="form" label-width="80px" label-position="top" :rules="rules">
        <div style="color: #F56C6C;font-size: 14px">{{this.$t('case.form.requiredMsg')}}</div>
        <el-form-item :label="$t('case.form.contact.label')" prop="contact">
          <el-input v-model="form.contact" :placeholder="$t('case.form.contact.placeholder')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('case.form.storage.label')" prop="storage">
          <el-radio-group v-model="form.storage">
            <el-radio v-for="item in storages" :label="item" :key="item"/>
          </el-radio-group>
          <el-input class="other-input" v-model="otherStorage" :placeholder="$t('case.form.storage.placeholder')"
                    @change="otherStorageChange" style="width:850px;position: absolute; top: 160px; left: 80px;"></el-input>
        </el-form-item>
        <el-form-item :label="$t('case.form.incidents.label')" prop="incidents">
          <el-radio-group v-model="form.incidents">
            <el-radio v-for="item in incidents" :label="item" :key="item"/>
          </el-radio-group>
          <el-input class="other-input" v-model="otherIncident" :placeholder="$t('case.form.incidents.placeholder')"
                    @change="otherIncidentChange" style="width:850px;position: absolute; top: 296px; left: 80px;"></el-input>
        </el-form-item>
        <el-form-item :label="$t('case.form.loss_usd.label')" prop="loss_usd">
          <el-input v-model="form.loss_usd" :placeholder="$t('case.form.loss_usd.placeholder')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('case.form.loss_addr.label')" prop="loss_addr">
          <el-input v-model="form.loss_addr" :placeholder="$t('case.form.loss_addr.placeholder')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('case.form.remark.label')" prop="remark">
          <el-input type="textarea" v-model="form.remark" :rows="5" :placeholder="$t('case.form.remark.placeholder')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{ this.$t('case.form.commitBtn') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { collect } from "@/api/case";
export default {
  name: "case",
  data() {
    return {
      showNotify: false,
      showNotifyTime: 1.5,
      storages: this.$t('case.form.storage.data'),
      otherStorage:'',
      incidents: this.$t('case.form.incidents.data'),
      otherIncident:'',
      form: {
        contact: null,
        storage: null,
        incidents: null,
        loss_usd: null,
        loss_addr: null,
        remark: null
      },
      rules: {
        contact: [
          { required: true, message: this.$t('case.form.contact.requiredWarnMsg'), trigger: 'blur' },
        ],
        // storage: [
        //   { required: true, message: this.$t('case.form.storage.requiredWarnMsg'), trigger: 'blur' },
        // ],
        incidents: [
          { required: true, message: this.$t('case.form.incidents.requiredWarnMsg'), trigger: 'blur' },
        ],
        loss_usd: [
          { required: true, message: this.$t('case.form.loss_usd.requiredWarnMsg'), trigger: 'blur' },
        ],
        loss_addr: [
          { required: true, message: this.$t('case.form.loss_addr.requiredWarnMsg'), trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    otherStorageChange(val) {
      if(val){
        this.form.storage=this.$t('case.form.storage.other')
      }
    },
    otherIncidentChange(val) {
      if(val){
        this.form.incidents=this.$t('case.form.incidents.other')
      }
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {...this.form}
          if(this.form.storage === this.$t('case.form.storage.other') && this.otherStorage){
            data.storage = this.otherStorage
          }
          if(this.form.incidents === this.$t('case.form.incidents.other') && this.otherIncident){
            data.incidents = this.otherIncident
          }
          collect(data).then(res => {
            if(res.data.code === 0){
              this.showNotifyDiv()
            }
          })
        }
      });
    },
    showNotifyDiv(){
      this.showNotify = true
      this.$refs.form.resetFields()
      setTimeout(() => {
        this.goBack();
      }, this.showNotifyTime * 1000);
    },
    goBack(){
      this.showNotify = false;
      this.$refs.form.resetFields()
      this.$router.back()
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .notify{
    width: 580px;
    height: 150px;
    position: absolute;
    top: 60px;
    left: calc(50% - 290px);
    background-color: #FFFFFF;
    border-radius: 4px;
    z-index: 9999999;
    box-shadow:0px 1px 8px 0px rgba(0,0,0,0.63);

    &-title{
      margin: 40px 40px 0 40px;
      display: flex;
      height: 24px;
      line-height: 24px;
      font-size: 24px;
      i:first-child{
        margin-right: 12px;
      }
      i:last-child{
        width: 16px;
        height: 16px;
        position: absolute;
        top: 32px;
        right: 32px;
      }
      label{
        font-size: 18px;
        font-weight: bold;
        color: #000000e6;
      }
    }
    &-content{
      margin: 12px 24px 24px 75px;
      p{
        margin: 0;
        font-size: 16px;
      }
      /deep/.el-button{
        background-color: #1464FF;
        color: #FFFFFF;
        position: absolute;
        bottom: 24px;
        right: 24px;
        width: 88px;
        height: 32px;
        padding: 7px 16px;
      }
    }
  }

  .back {
    margin-top: 100px;
    width: 984px;
    text-align: left;
    height: 24px;
    line-height: 24px;
    img{
      width: 20px;
      height: 20px;
      position: relative;
      top: 3px;
      margin-right: 10px;
    }
    font-weight: bold;
  }
  .warn{
    width: 914px;
    //height:82px;
    text-align: justify;
    margin:40px 0px 10px;
    background-color: #FCF3F4;
    border-radius: 12px;
    box-shadow:0px 1px 8px 0px rgba(0,0,0,0.63);
    div{
      margin: 20px
    }
  }
  &-form {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .form {
      width: 914px;
    }

  }
  /deep/.el-select{
    width: 100%;
  }
  /deep/.el-page-header{
    width: 528px
  }
  /deep/.el-radio-group{
    display: flex;
    flex-direction: column;
    .el-radio{
      height: 44px;
    }
  }
  /deep/.el-input{
    input{
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }
  /deep/.el-form-item__error{
    left: 15px;
  }
}
</style>
